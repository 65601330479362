<template>
  <div class="not-found">
    <img
      class="not-found__image"
      src="@/assets/images/404.png"
      alt="Страница не найдена"
    />
    <h3 class="not-found__title">Страница не найдена</h3>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped lang="scss">
.not-found {
  padding: 90px 0;
  text-align: center;

  &__image {
    margin-bottom: 30px;
  }
}
</style>
