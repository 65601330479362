<template>
  <div class="mail-view">
    <mail />
  </div>
</template>

<script>
import Mail from "@/components/mail/Mail.vue";

export default {
  name: "MailView",
  components: {
    Mail,
  },
};
</script>

<style scoped lang="scss"></style>
