<template>
  <transition-group
    name="flip-list"
    tag="ul"
    class="cards-list"
    mode="out-in"
    appear
  >
    <cards-list-item
      class="cards-list__item"
      v-for="person in persons"
      :key="person.id"
      :person="person"
    />
  </transition-group>
</template>

<script>
import CardsListItem from "./CardsListItem.vue";

export default {
  name: "CardsList",
  components: {
    CardsListItem,
  },
  props: {
    persons: Array,
  },
};
</script>

<style scoped lang="scss">
.cards-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 33px;
  grid-row-gap: 32px;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
