<template>
  <section id="gallery" class="gallery">
    <h3 class="gallery__heading">Галерея рисунков</h3>

    <gallery-filter
      class="gallery__filter"
      :filter="filter"
      :searchText="searchText"
      @change-filter="filter = $event"
      @search="searchText = $event"
    />

    <ul class="gallery__list gallery-list">
      <gallery-item
        class="gallery-list__item"
        v-for="person in personsToShow"
        :key="person.id"
        :person="person"
      />
    </ul>

    <transition name="fade">
      <div
        class="catalogue__empty-stub empty-stub"
        v-show="searchText && !personsToShow.length"
      >
        <div class="empty-stub__text">
          К сожалению по вашему запросу <br />
          ничего не найдено..
        </div>
        <img
          src="@/assets/images/Empty_Stub.svg"
          alt="Иконка отсутствия результатов поиска"
        />
      </div>
    </transition>

    <pagination
      v-if="totalPages > 1"
      :totalPages="totalPages"
      :currentPage="currentPage"
      @changePage="changePage"
    />
  </section>
</template>

<script>
import GalleryFilter from "./GalleryFilter.vue";
import GalleryItem from "./GalleryItem.vue";
import Pagination from "../Pagination.vue";
import PersonsService from "@/services/PersonsService";

export default {
  name: "Gallery",
  components: { GalleryFilter, GalleryItem, Pagination },
  data() {
    return {
      filter: {
        sortBy: "updatedAt", // updatedAt || count
        sortDirection: "desc", // desc || asc
      },
      searchText: "",
      persons: [],
      pageSize: 5,
      currentPage: 1,
    };
  },
  methods: {
    changePage(pageNumber) {
      this.currentPage = pageNumber;
      this.$scrollTo("#gallery", 0);
    },
  },
  watch: {
    filteredPersons() {
      this.currentPage = 1;
    },
  },
  computed: {
    filteredPersons() {
      let persons = this.persons.filter((person) =>
        person.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
      return persons.sort((a, b) => {
        switch (this.filter.sortBy) {
          case "count":
            return this.filter.sortDirection === "desc"
              ? b.pictures.count - a.pictures.count
              : a.pictures.count - b.pictures.count;
          case "updatedAt":
            return this.filter.sortDirection === "desc"
              ? b.pictures.updatedAt - a.pictures.updatedAt
              : a.pictures.updatedAt - b.pictures.updatedAt;
          default:
            break;
        }
      });
    },
    personsToShow() {
      return this.filteredPersons.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    },
    totalPages() {
      return Math.ceil(this.filteredPersons.length / this.pageSize);
    },
  },
  async created() {
    this.persons = await PersonsService.getPersons();
  },
};
</script>

<style scoped lang="scss">
.gallery {
  padding-top: 48px;

  &__heading {
    border-bottom: 1px solid #a4a5a9;
    padding-bottom: 14px;
    margin-bottom: 32px;
  }

  &__filter {
    margin-bottom: 72px;

    @media screen and (max-width: 1023px) {
      margin-bottom: 48px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 12px;
    }
  }

  &__list {
    margin-bottom: 64px;

    @media screen and (max-width: 767px) {
      margin-bottom: 56px;
    }
  }

  &__empty-stub {
    margin-bottom: 84px;
  }
}

.gallery-list {
  &__item {
    border-bottom: 1px solid #a4a5a9;
    margin-bottom: 40px;

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
