<template>
  <ul class="filter">
    <li class="filter__search">
      <div class="search">
        <input
          type="text"
          class="search__input"
          placeholder="Поиск по имени и фамилии"
          :value="searchText"
          @input="$emit('search', $event.target.value)"
        />
        <transition name="fade-fast" mode="out-in">
          <img
            v-if="!searchText"
            class="search__icon"
            src="@/assets/images/Search.svg"
            alt="Поиск"
          />

          <button
            @click="$emit('search', '')"
            class="search__reset-button"
            v-else
          >
            <svg
              width="27"
              height="28"
              viewBox="0 0 27 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="25.4602"
                width="34"
                height="3"
                transform="rotate(-45 0 25.4602)"
              />
              <rect
                x="2.12109"
                y="0.95837"
                width="34"
                height="3"
                transform="rotate(45 2.12109 0.95837)"
              />
            </svg>
          </button>
        </transition>
      </div>
    </li>

    <li class="filter__sort sort">
      <h4 class="sort__heading">Сортировать по:</h4>
      <button
        @click="sortBy('updatedAt')"
        class="sort__button button"
        :class="{
          'button--active': filter.sortBy === 'updatedAt',
          'button--asc-sort':
            filter.sortBy === 'updatedAt' && filter.sortDirection === 'asc',
        }"
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="button__icon"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11 19L5 12.4068L6.41825 11.0972L10.038 15.0749V4H11.962V15.0749L15.5817 11.0972L17 12.4068L11 19Z"
          />
        </svg>
        <span>Обновления</span>
      </button>
      <button
        @click="sortBy('count')"
        class="sort__button button"
        :class="{
          'button--active': filter.sortBy === 'count',
          'button--asc-sort':
            filter.sortBy === 'count' && filter.sortDirection === 'asc',
        }"
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="button__icon"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11 19L5 12.4068L6.41825 11.0972L10.038 15.0749V4H11.962V15.0749L15.5817 11.0972L17 12.4068L11 19Z"
          />
        </svg>
        <span>Кол-во рисунков</span>
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "GalleryFilter",
  props: {
    filter: Object,
    searchText: String,
  },

  methods: {
    sortBy(sign) {
      let direction;
      if (this.filter.sortBy === sign) {
        direction = this.filter.sortDirection === "asc" ? "desc" : "asc";
      } else {
        direction = "desc";
      }

      this.$emit("change-filter", {
        sortBy: sign,
        sortDirection: direction,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &__search {
    margin-right: 24px;

    @media screen and (max-width: 1023px) {
      width: 100%;
      margin-bottom: 24px;
    }

    @media screen and (max-width: 479px) {
      margin-right: 0;
    }
  }

  &__sort {
    margin-right: 24px;

    @media screen and (max-width: 1023px) {
      width: 100%;
      margin-bottom: 24px;
    }

    @media screen and (max-width: 767px) {
      margin-right: 0;
    }
  }

  &__view {
    @media screen and (max-width: 767px) {
      display: none !important;
    }
  }
}

.sort {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__heading {
    margin-right: 16px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  &__button {
    margin-left: 16px;

    @media screen and (max-width: 767px) {
      margin: 0 16px 16px 0;
    }
  }
}

.search {
  position: relative;
  width: 328px;
  max-width: 100%;

  &__input {
    padding: 10px 28px 10px 0;
    border: none;
    border-bottom: 2px solid #363636;
    background: transparent;
    font-weight: 500;
    font-size: 18px;
    width: 100%;
    opacity: 0.85;
    transition: opacity ease 0.2s;

    &::placeholder {
      font-size: 18px;
      opacity: 1;
      color: #363636;
    }

    &:focus {
      opacity: 1;
    }
  }

  &__icon {
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  &__reset-button {
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
    transition: color ease 0.2s;

    &:hover,
    &:focus {
      color: #bc2626;
    }
  }

  @media screen and (max-width: 479px) {
    width: 100%;
  }
}
</style>
