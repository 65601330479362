<template>
  <section class="mail">
    <h3 class="mail__date" v-if="this.mail.date">{{ formattedDate }}</h3>
    <div class="mail__author">Автор: {{ mail.author }}</div>

    <div class="mail__content">
      <div class="mail__preview">
        <light-gallery
          :images="images"
          :index="index"
          background="rgba(0, 0, 0, 0.85)"
          :disable-scroll="true"
          @close="index = null"
        />

        <div class="slider">
          <ul class="slider__list" v-if="images.length">
            <template v-for="(image, thumbIndex) in images">
              <li
                class="slider__list-item"
                v-show="thumbIndex === activePage - 1"
                :key="thumbIndex"
                @click="index = thumbIndex"
              >
                <app-image
                  :imageUrl="image.url"
                  altText="Скан письма"
                  proportions="142.857%"
                />
              </li>
            </template>
          </ul>
          <div class="slider__controls" v-if="images.length > 1">
            <button
              class="slider__control-button custom-focus"
              :class="{
                'slider__control-button--disabled': activePage === 1,
              }"
              :disabled="activePage === 1"
              @click="showPrevPage()"
            >
              <svg
                width="70"
                height="13"
                viewBox="0 0 70 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.66765 0L0 6.5L6.66765 13L8.07355 11.6295L3.80591 7.46912H70V5.53088H3.80591L8.07355 1.37054L6.66765 0Z"
                />
              </svg>
            </button>
            <span class="slider__active-page"
              >{{ activePage }}/{{ images.length }}</span
            >
            <button
              class="slider__control-button custom-focus"
              :class="{
                'slider__control-button--disabled':
                  activePage === images.length,
              }"
              :disabled="activePage === images.length"
              @click="showNextPage()"
            >
              <svg
                width="70"
                height="13"
                viewBox="0 0 70 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M63.3323 0L70 6.5L63.3323 13L61.9264 11.6295L66.1941 7.46912H0V5.53088H66.1941L61.9264 1.37054L63.3323 0Z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div class="mail__text" v-if="mail.text">
        <div class="mail__header">
          <h4 class="mail__title">Транскрипция письма</h4>
          <div class="tooltip">
            <button class="tooltip__button">
              <img
                src="@/assets/images/Info.svg"
                alt="Дополнительная информация о транскрипции"
                class="tooltip__icon"
              />
            </button>
            <div class="tooltip__content">
              Текст в расшифровке письма сохраняет орфографию и пунктуацию
              автора
            </div>
          </div>
        </div>
        <p v-html="mail.text"></p>
      </div>
    </div>
  </section>
</template>

<script>
import { LightGallery } from "vue-light-gallery";
import AppImage from "@/components/Image.vue";
import MailsService from "@/services/MailsService";
import moment from "moment";
import "moment/locale/ru";

export default {
  name: "Mail",
  components: { LightGallery, AppImage },
  data() {
    return {
      mail: {},
      activePage: 1,
      index: null,
      images: [],
    };
  },
  methods: {
    showPrevPage() {
      if (this.activePage > 1) {
        this.activePage--;
      }
    },
    showNextPage() {
      if (this.activePage < this.mail.imagesUrls.length) {
        this.activePage++;
      }
    },
    markAsRead() {
      let readMails = localStorage.getItem("readMails");
      readMails = JSON.parse(readMails) || [];

      if (readMails.includes(this.mail.id)) {
        return;
      }

      readMails.push(this.mail.id);
      localStorage.setItem("readMails", JSON.stringify(readMails));
    },
  },
  computed: {
    formattedDate() {
      return this.mail.date ? moment(this.mail.date).format("LL") : "";
    },
  },
  async created() {
    this.mail = await MailsService.getMail(this.$route.params.id);

    this.images = this.mail.imagesUrls.map((url, index) => ({
      url,
      title: `Страница ${index + 1}`,
    }));

    this.markAsRead();
  },
  mounted() {
    moment.locale("ru");
  },
};
</script>

<style scoped lang="scss">
.mail {
  padding: 36px 0 100px;

  &__date {
    @media screen and (max-width: 1023px) {
      text-align: center;
    }
  }

  &__author {
    margin-bottom: 15px;

    @media screen and (max-width: 1023px) {
      text-align: center;
    }
  }

  &__content {
    display: flex;

    @media screen and (max-width: 1023px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__preview {
    width: 40%;
    flex-shrink: 0;
    margin-right: 32px;

    @media screen and (max-width: 1023px) {
      max-width: 100%;
      width: 550px;
      margin-bottom: 48px;
      margin-right: 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      margin-bottom: 18px;
    }
  }

  &__title {
    margin-right: 24px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin: 0 0 18px;
    }
  }

  &__text {
    flex: 1;
    max-width: 688px;

    @media screen and (max-width: 1023px) {
      flex: auto;
      width: 100%;
    }
  }

  @media screen and (max-width: 1023px) {
    padding-bottom: 55px;
  }
}

.slider {
  &__list {
    border: 3px solid #d8d7d7;
    padding: 20px;
    background-color: #eaebed;
    margin-bottom: 20px;
  }

  &__list-item {
    cursor: pointer;
    transition: all ease 0.2s;

    img {
      vertical-align: top;
    }

    &:hover {
      transform: scale(1.03);
    }
  }

  &__controls {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__control-button {
    padding: 6px 12px;
    transition: all ease 0.2s;

    svg {
      vertical-align: middle;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    &:hover:not(.slider__control-button--disabled) {
      color: #bc2626;
    }
  }

  &__active-page {
    margin: 0 12px;
  }
}

::v-deep .light-gallery__modal {
  max-width: 100vw;
}

::v-deep .light-gallery__container {
  padding: 0 15px !important;
}

::v-deep .light-gallery__content {
  overflow: hidden;
}
</style>
