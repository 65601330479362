<template>
  <ul class="filter">
    <li class="filter__sort sort">
      <h4 class="sort__heading">Сортировать по:</h4>
      <button
        @click="sortBy('date')"
        class="sort__button button"
        :class="{
          'button--active': filter.sortBy === 'date',
          'button--asc-sort':
            filter.sortBy === 'date' && filter.sortDirection === 'asc',
        }"
      >
        <svg
          class="button__icon"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11 19L5 12.4068L6.41825 11.0972L10.038 15.0749V4H11.962V15.0749L15.5817 11.0972L17 12.4068L11 19Z"
          />
        </svg>
        <span>Дате</span>
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "MailsFilter",
  props: {
    filter: Object,
  },

  methods: {
    sortBy(sign) {
      let direction;
      if (this.filter.sortBy === sign) {
        direction = this.filter.sortDirection === "asc" ? "desc" : "asc";
      } else {
        direction = "desc";
      }

      this.$emit("change-filter", {
        sortBy: sign,
        sortDirection: direction,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sort {
  display: flex;
  align-items: center;

  &__heading {
    margin-right: 16px;
  }

  &__button {
    margin-left: 16px;
  }
}
</style>
