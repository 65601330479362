<template>
  <div id="app" class="app">
    <div class="container">
      <app-header
        @open-main-modal="showMainModal = true"
        @open-additional-modal="showAdditionalModal = true"
      />
      <main>
        <keep-alive>
          <!-- via https://stackoverflow.com/a/40405563/244556 -->
          <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath" />
        </keep-alive>

        <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" />
      </main>
    </div>

    <transition name="fade">
      <div class="modal" v-show="showMainModal" @click="showMainModal = false">
        <div class="modal__content" @click.stop="">
          <button class="modal__close-button" @click="showMainModal = false">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.0003 29.3332C8.63633 29.3332 2.66699 23.3638 2.66699 15.9998C2.66699 8.63584 8.63633 2.6665 16.0003 2.6665C23.3643 2.6665 29.3337 8.63584 29.3337 15.9998C29.3337 23.3638 23.3643 29.3332 16.0003 29.3332ZM16.0003 26.6665C18.8293 26.6665 21.5424 25.5427 23.5428 23.5423C25.5432 21.5419 26.667 18.8288 26.667 15.9998C26.667 13.1709 25.5432 10.4578 23.5428 8.45737C21.5424 6.45698 18.8293 5.33317 16.0003 5.33317C13.1713 5.33317 10.4582 6.45698 8.45785 8.45737C6.45747 10.4578 5.33366 13.1709 5.33366 15.9998C5.33366 18.8288 6.45747 21.5419 8.45785 23.5423C10.4582 25.5427 13.1713 26.6665 16.0003 26.6665ZM16.0003 14.1145L19.771 10.3425L21.6577 12.2292L17.8857 15.9998L21.6577 19.7705L19.771 21.6572L16.0003 17.8852L12.2297 21.6572L10.343 19.7705L14.115 15.9998L10.343 12.2292L12.2297 10.3425L16.0003 14.1145Z"
              />
            </svg>
          </button>

          <h3>Загрузить письмо</h3>
          <p class="modal__description">
            Если у Вас есть письма от политзаключенных, и Вы хотите поделиться
            ими с музеем, напишите нам на почту.
          </p>
          <ul class="modal__info list">
            <li class="list__item">
              Мы принимаем письма в формате их сканов, либо четких фотографий.
            </li>
            <li class="list__item">
              Вы можете скрыть сами, или указать нам, какие фрагменты текста
              должны быть скрыты при публикации на скане/фотографии письма - и
              мы скроем их.
            </li>
            <li class="list__item">
              Если вы не уверены, что политзаключенный, чьими письмами вы хотели
              бы поделиться с музеем, хотел бы этого, приберегите их до
              получения от него согласия.
            </li>
          </ul>

          <div class="modal__email">
            Адрес нашей электронной почты: <br /><a
              href="mailto:lettersmuseum@gmail.com"
              >lettersmuseum@gmail.com</a
            >
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        class="modal"
        v-show="showAdditionalModal"
        @click="showAdditionalModal = false"
      >
        <div class="modal__content" @click.stop="">
          <button
            class="modal__close-button"
            @click="showAdditionalModal = false"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.0003 29.3332C8.63633 29.3332 2.66699 23.3638 2.66699 15.9998C2.66699 8.63584 8.63633 2.6665 16.0003 2.6665C23.3643 2.6665 29.3337 8.63584 29.3337 15.9998C29.3337 23.3638 23.3643 29.3332 16.0003 29.3332ZM16.0003 26.6665C18.8293 26.6665 21.5424 25.5427 23.5428 23.5423C25.5432 21.5419 26.667 18.8288 26.667 15.9998C26.667 13.1709 25.5432 10.4578 23.5428 8.45737C21.5424 6.45698 18.8293 5.33317 16.0003 5.33317C13.1713 5.33317 10.4582 6.45698 8.45785 8.45737C6.45747 10.4578 5.33366 13.1709 5.33366 15.9998C5.33366 18.8288 6.45747 21.5419 8.45785 23.5423C10.4582 25.5427 13.1713 26.6665 16.0003 26.6665ZM16.0003 14.1145L19.771 10.3425L21.6577 12.2292L17.8857 15.9998L21.6577 19.7705L19.771 21.6572L16.0003 17.8852L12.2297 21.6572L10.343 19.7705L14.115 15.9998L10.343 12.2292L12.2297 10.3425L16.0003 14.1145Z"
              />
            </svg>
          </button>

          <h3>Дети пишут политзаключенным</h3>

          <p class="modal__description modal__description--justify">
            Вместе с инициативой
            <a class="simple-link" href="https://dissidentby.com/"
              >dissidentby</a
            >
            мы представляем проект
            <strong>"Дети пишут политзаключенным"</strong>, в рамках которого
            предлагаем беларусам рассказать своим детям историю одного из более
            чем тысячи политзаключенных на выбор, и дать ребенку возможность
            написать ему небольшое письмо. Такое письмо можно анонимно отправить
            в виде фотографии или скана на нашу почту - и в скором времени оно
            появится на
            <router-link
              class="simple-link"
              :to="{
                name: 'Profile',
                params: { id: '7eaf9c8b-3f63-4def-810e-8eab56b68bd0' },
              }"
            >
              сайте</router-link
            >, а его распечатанную версию мы отправим политзаключенному.
          </p>

          <div class="modal__email">
            Адрес нашей электронной почты: <br /><a
              href="mailto:lettersmuseum@gmail.com"
              >lettersmuseum@gmail.com</a
            >
          </div>
        </div>
      </div>
    </transition>

    <app-footer class="app__footer" />
  </div>
</template>

<script>
import AppHeader from "./components/Header.vue";
import AppFooter from "./components/Footer.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      showMainModal: false,
      showAdditionalModal: false,
    };
  },
  created() {
    document.addEventListener("keyup", (evt) => {
      if (evt.key === "Escape") {
        this.closeModals();
      }
    });
  },
  watch: {
    $route() {
      this.closeModals();
    },
  },
  methods: {
    closeModals() {
      this.showMainModal = false;
      this.showAdditionalModal = false;
    },
  },
};
</script>

<style lang="scss">
.app {
  min-height: 100vh;
  height: auto !important; /* modern Browser */
  display: flex;
  flex-direction: column;

  &__footer {
    margin-top: auto;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;

  &__content {
    margin: auto;
    max-width: 98vw;
    max-height: 98vh;
    position: relative;
    width: 930px;
    background-color: #fff;
    padding: 56px 64px 64px 56px;

    @media screen and (max-width: 767px) {
      padding: 40px 48px 48px 40px;
    }
  }

  &__close-button {
    position: absolute;
    right: 25px;
    top: 25px;
    width: 32px;
    height: 32px;
    color: #605f5f;
    transition: all ease 0.2s;

    &:hover,
    &:focus {
      color: #171717;
    }
  }

  &__description {
    margin-bottom: 24px;

    &--justify {
      text-align: justify;
    }
  }

  &__info {
    font-weight: 400;
    margin-bottom: 26px;
  }

  &__email {
    text-align: right;
    font-weight: 400;

    a {
      display: inline-block;
      margin-top: 10px;
      color: #252122;
      font-weight: 600;
      transition: all ease 0.2s;

      &:hover,
      &:focus {
        color: #bc2626;
      }
    }
  }
}
</style>
