import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// eslint-disable-next-line no-unused-vars
const firebaseConfigProd = {
  apiKey: "AIzaSyDFdJINupHrT3tbTWoY39Eq4OHoUkj8ptg",
  authDomain: "letters-museum.firebaseapp.com",
  projectId: "letters-museum",
  storageBucket: "letters-museum.appspot.com",
  messagingSenderId: "178607076119",
  appId: "1:178607076119:web:17d0544f8adc3023233d52",
};

// eslint-disable-next-line no-unused-vars
const firebaseConfigTest = {
  apiKey: "AIzaSyBEPXGxiB55pymZZ2PIEMMMxgb38Obpsnw",
  authDomain: "letters-museum-test.firebaseapp.com",
  projectId: "letters-museum-test",
  storageBucket: "letters-museum-test.appspot.com",
  messagingSenderId: "102448845954",
  appId: "1:102448845954:web:192b2ac4c56d8d8204109b",
};

export const firebaseApp = initializeApp(firebaseConfigProd);

export const storage = getStorage();

export const firestore = getFirestore();
