<template>
  <li class="gallery-item">
    <router-link
      class="gallery-item__header person"
      :to="{ name: 'Profile', params: { id: person.id } }"
    >
      <div class="person__image-wrapper">
        <app-image
          class="person__image"
          :imageUrl="avatarUrl"
          proportions="122.857%"
        />
      </div>

      <div class="person__info-wrapper">
        <h3 class="person__name">{{ person.name }}</h3>
        <ul class="person__info">
          <li class="person__info-item">
            <div class="subheading">Последнее обновление</div>
            <div>{{ formattedDate }}</div>
          </li>
          <li class="person__info-item person__info-item--padding-left">
            <div class="subheading">Кол-во рисунков</div>
            <div>{{ person.pictures.count }}</div>
          </li>
        </ul>
      </div>
    </router-link>

    <light-gallery
      :images="images"
      :index="index"
      background="rgba(0, 0, 0, 0.85)"
      :disable-scroll="true"
      @close="index = null"
    />

    <transition-group
      name="flip-list"
      tag="ul"
      class="gallery-item__pictures pictures"
      mode="out-in"
      appear
    >
      <li
        class="pictures__item picture"
        v-for="(imageUrl, thumbIndex) in person.pictures.imagesUrls"
        :key="imageUrl"
        @click="index = thumbIndex"
      >
        <div class="picture__image-wrapper">
          <app-image
            class="picture__image"
            :imageUrl="imageUrl"
            altText="Рисунок политзаключенного"
          />
        </div>
      </li>
    </transition-group>
  </li>
</template>

<script>
import { LightGallery } from "vue-light-gallery";
import moment from "moment";
import "moment/locale/ru";
import AppImage from "@/components/Image.vue";
import imageStub from "@/assets/images/Avatar.png";

export default {
  name: "GalleryItem",
  components: { AppImage, LightGallery },
  props: {
    person: Object,
  },
  data() {
    return {
      index: null,
    };
  },
  computed: {
    avatarUrl() {
      return this.person.avatarUrl || imageStub;
    },
    formattedDate() {
      return this.person.pictures.updatedAt
        ? moment(this.person.pictures.updatedAt).format("LL")
        : "";
    },
    images() {
      return this.person.pictures.imagesUrls.map((url, index) => ({
        url,
        title: `Рисунок ${this.person.name} ${index + 1}`,
      }));
    },
  },
  mounted() {
    moment.locale("ru");
  },
};
</script>

<style scoped lang="scss">
.gallery-item {
  &__header {
    margin-bottom: 32px;
  }

  &__pictures {
    margin-bottom: 32px;
  }
}

.person {
  display: inline-flex;
  text-decoration: none;
  color: inherit;

  &__image-wrapper {
    width: 70px;
    margin-right: 32px;
  }

  &__image {
    transition: all ease 0.3s;
    filter: grayscale(1);

    @media screen and (max-width: 1023px) {
      filter: none;
    }
  }

  &__info-wrapper {
    white-space: nowrap;
  }

  &__name {
    margin-bottom: 16px;
    transition: all ease 0.3s;
  }

  &__info {
    display: flex;
  }

  &__info-item {
    min-width: 0;
    min-width: 0;

    &--padding-left {
      padding-left: 12px;
    }
  }

  &:hover,
  &:focus {
    .person__image {
      filter: none;
    }

    .person__name {
      color: #bc2626;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: center;
  }
}

.pictures {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 33px;
  grid-row-gap: 33px;
  overflow: hidden;
  position: relative;

  &__item {
    max-width: 100%;
  }

  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: minmax(0, 328px);
    justify-content: center;
  }
}

.picture {
  &__image-wrapper {
    padding: 5px;
    border: 1px solid #d8d7d7;
    background-color: #eaebed;
  }

  &__image {
    transition: all ease 0.2s;
    cursor: pointer;

    &:hover {
      transform: scale(1.03);
    }
  }
}

::v-deep .light-gallery__modal {
  max-width: 100vw;
}

::v-deep .light-gallery__container {
  padding: 0 15px !important;
}

::v-deep .light-gallery__content {
  overflow: hidden;
}
</style>
