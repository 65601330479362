import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import { firestore } from "@/firebase";

function toModel(personResp) {
  return {
    id: personResp.id,
    updatedAt: personResp.updatedAt?.toDate()?.getTime() || 0,
    name: personResp.name,
    avatarUrl: personResp.avatarUrl,
    status: personResp.status,
    mailCount: personResp.lettersCount,
    arrestDate: personResp.arrestDate?.toDate(),
    releaseDate: personResp.releaseDate?.toDate(),
    birthYear: personResp.birthYear,
    city: personResp.city,
    prison: personResp.prison,
    verdict: personResp.verdict,
    fact: personResp.fact,
    politzekUrl: personResp.politzekMeLink,
    history: personResp.history?.replaceAll("\n", "<br />"),
    quote: personResp.quote
      ? {
          text: personResp.quote.text,
          mailDate: personResp.quote.date?.toDate(),
          mailId: personResp.quote.letterId,
        }
      : null,
    pictures: {
      count: personResp.pictures?.pictures?.length || 0,
      updatedAt:
        personResp.pictures?.updatedAt?.toDate() ?? new Date(1970, 1, 1),
      imagesUrls: personResp.pictures?.pictures?.map((p) => p.url),
    },
  };
}

async function getPersons() {
  const query = collection(firestore, "prisoners");
  const response = await getDocs(query);

  return response.docs.map((doc) => toModel(doc.data()));
}

async function getPerson(id) {
  const docRef = doc(firestore, "prisoners", id);
  const response = await getDoc(docRef);

  const prisoner = response.data();
  return prisoner ? toModel(prisoner) : null;
}

export default {
  getPerson,
  getPersons,
};
