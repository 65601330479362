<template>
  <div class="home-view">
    <section class="home-view__heading">
      <div class="home-view__title">
        <div class="h1">Летят письма</div>
        <h1>Открытый онлайн-музей писем политзаключенных Беларуси</h1>
      </div>
      <div class="home-view__letters-info">
        <div class="h1 text-center">{{ lettersCount }}</div>
        <h1 class="text-center">{{ lettersText }}</h1>
      </div>
    </section>

    <div class="home-view__image"></div>

    <catalogue id="catalogue" />

    <section id="about" class="home-view__about about">
      <div class="about__image">
        <img src="@/assets/images/Logo.svg" alt="Логотип" />
      </div>

      <div class="about__info">
        <h2>О проекте</h2>
        <p class="about__text">
          Мы инициативная группа беларусов из разных стран мира. Наша цель —
          собрать, сохранить и показать письма политзаключенных Беларуси, как
          историческую ценность, здесь и сейчас.
        </p>
        <p class="about__text">
          Для связи с нами используйте почту
          <a
            class="about__link simple-link"
            href="mailto:lettersmuseum@gmail.com"
            >lettersmuseum@gmail.com</a
          >
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import Catalogue from "@/components/catalogue/Catalogue.vue";
import MailsService from "@/services/MailsService";
import LettersMuseumUtils from "@/LettersMuseumUtils";

export default {
  name: "HomeView",
  components: {
    Catalogue,
  },
  activated() {
    if (this.$route.query?.scrollTo) {
      this.$scrollTo(`#${this.$route.query?.scrollTo}`, 0);
    }
  },
  data() {
    return {
      lettersCount: 0,
      lettersText: "Писем",
    };
  },
  async created() {
    let lettersCount = await MailsService.countMails();
    this.lettersCount = lettersCount;
    this.lettersText = LettersMuseumUtils.capitalizeFirstLetter(
      LettersMuseumUtils.calcLettersText(lettersCount)
    );
  },
};
</script>

<style scoped lang="scss">
.home-view {
  &__heading {
    padding: 60px 0;
    border-bottom: 2px solid #a4a5a9;

    @media screen and (min-width: 768px) {
      display: flex;
    }

    .home-view__title {
      @media screen and (min-width: 768px) {
        flex-grow: 1;
      }
    }

    .home-view__letters-info {
      transform: scale(1.5);

      @media screen and (min-width: 768px) {
        padding-left: 32px;
        padding-right: 32px;
      }
      @media screen and (max-width: 767px) {
        padding-top: 32px;
      }
    }
  }

  &__image {
    min-height: 544px;
    margin: 55px 0 110px;
    background-image: url("~@/assets/images/Main_Image.jpg");
    background-position: center;

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      margin: 40px 0 55px;
    }

    @media screen and (max-width: 767px) {
      margin: 25px 0 50px;
      background-image: url("~@/assets/images/Main_Image_Small.jpg");
      background-size: 100% auto;
      background-position: top center;
      min-height: 380px;
    }
  }
}

.about {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0 100px;

  &__image {
    width: 324px;
    margin-right: 128px;

    @media screen and (max-width: 1023px) {
      margin-right: 100px;
    }

    @media screen and (max-width: 767px) {
      margin-right: 40px;
      order: 5;
    }
  }

  &__info {
    width: 590px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__text {
    font-weight: 600;
  }

  @media screen and (max-width: 767px) {
    padding: 25px 0 50px;
    flex-wrap: wrap;
  }
}
</style>
