<template>
  <transition-group
    name="flip-list"
    tag="ul"
    class="cards-tiles"
    mode="out-in"
    appear
  >
    <tile
      class="cards-tiles__item"
      v-for="person in persons"
      :key="person.id"
      :person="person"
    />
  </transition-group>
</template>

<script>
import Tile from "./Tile.vue";

export default {
  name: "CardsTiles",
  components: {
    Tile,
  },
  props: {
    persons: Array,
  },
};
</script>

<style scoped lang="scss">
.cards-tiles {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 33px;
  grid-row-gap: 48px;
  overflow: hidden;
  position: relative;

  &__item {
    max-width: 100%;
  }

  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(2, 328px);
    justify-content: center;
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: minmax(0, 328px);
    grid-row-gap: 40px;
  }
}
</style>
