<template>
  <section class="profile">
    <div class="profile__image-wrapper">
      <app-image
        class="profile__image"
        :imageUrl="person.avatarUrl"
        altText="Фото политзаключенного"
        proportions="124.348%"
      />
      <h2 class="profile__name visible-xs">{{ person.name }}</h2>
      <div class="profile__history" v-if="person.history">
        <h3>История</h3>
        <p>
          <span v-html="person.history"></span>
        </p>
      </div>
    </div>

    <div class="profile__info">
      <h2 class="profile__name hidden-xs">{{ person.name }}</h2>
      <div class="profile__info-header">
        <h3>
          Общая<br class="visible-sm" />
          информация
        </h3>
        <a
          v-if="person.politzekUrl"
          :href="person.politzekUrl"
          class="profile__politzek-link link"
          target="_blank"
        >
          <span class="link__pre-icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5 1.5H1V14.5H14V10.5H12V12.5H3V3.5H5V1.5ZM7.00008 3.5H10.5859L6.29297 7.79289L7.70718 9.20711L12.0001 4.91421V8.5H14.0001V1.5H7.00008V3.5Z"
              />
            </svg>
          </span>
          <span class="link__text">посмотреть на politzek.me</span>
          <span class="link__icon link__icon--fixed">
            <svg
              width="35"
              height="18"
              viewBox="0 0 35 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.7071 3.29285L35.4142 8.99995L29.7071 14.7071L28.2929 13.2928L31.5858 9.99995H0V7.99995H31.5858L28.2929 4.70706L29.7071 3.29285Z"
              />
            </svg>
          </span>
        </a>
      </div>
      <div class="profile__info-row">
        <ul class="profile__info-column">
          <li class="profile__info-block" v-if="person.birthYear">
            <div class="subheading">Год рождения</div>
            <div>{{ person.birthYear }}</div>
          </li>
          <li class="profile__info-block" v-if="person.arrestDate">
            <div class="subheading">Дата задержания</div>
            <div>{{ formatDate(person.arrestDate) }}</div>
          </li>
          <li class="profile__info-block" v-if="person.status === 'free'">
            <div class="subheading">Дата освобождения</div>
            <div>{{ formatDate(person.releaseDate) }}</div>
          </li>
          <li class="profile__info-block" v-if="calculatedStretch">
            <div class="subheading">
              {{ person.status === "free" ? "Был(а) вне воли" : "Вне воли" }}
            </div>
            <div>{{ calculatedStretch }}</div>
          </li>
          <li class="profile__info-block" v-if="person.city">
            <div class="subheading">Город</div>
            <div>{{ person.city }}</div>
          </li>
          <li class="profile__info-block">
            <div class="subheading">Кол-во писем</div>
            <div>{{ mails.length }}</div>
          </li>
        </ul>
        <ul class="profile__info-column">
          <li
            class="profile__info-block"
            v-if="person.status === 'in-prison' && person.prison"
          >
            <div class="subheading">Где сидит</div>
            <div>{{ person.prison }}</div>
          </li>
          <li class="profile__info-block" v-if="person.verdict">
            <div class="subheading">Статья</div>
            <div v-html="person.verdict"></div>
          </li>
          <li class="profile__info-block" v-if="person.fact">
            <div class="subheading">Факт из жизни</div>
            <div>{{ person.fact }}</div>
          </li>
        </ul>
      </div>

      <blockquote class="profile__quote quote" v-if="person.quote">
        <p class="quote__text" v-html="person.quote.text"></p>
        <router-link
          class="quote__cite-link link"
          :to="{ name: 'Mail', params: { id: person.quote.mailId } }"
        >
          <cite class="link__text" v-if="person.quote.mailDate"
            >Письмо от {{ formatDate(person.quote.mailDate) }}</cite
          >
        </router-link>
      </blockquote>

      <h3 id="mails">Письма</h3>
      <mails-filter
        class="profile__mails-filter"
        :filter="filter"
        @change-filter="filter = $event"
      ></mails-filter>

      <mails-list :mails="mailsToShow" />

      <div
        class="profile__empty-mails-stub empty-stub"
        v-show="!mailsToShow.length"
      >
        <div class="empty-stub__text">К сожалению пока тут нет писем..</div>
        <img
          src="@/assets/images/Empty_Stub.svg"
          alt="Иконка отсутствия писем"
        />
      </div>

      <pagination
        v-if="totalPages > 1"
        :totalPages="totalPages"
        :currentPage="currentPage"
        @changePage="changePage"
      />
    </div>
  </section>
</template>

<script>
import MailsFilter from "./MailsFilter.vue";
import MailsList from "./MailsList.vue";
import Pagination from "../Pagination.vue";
import AppImage from "@/components/Image.vue";
import PersonsService from "@/services/PersonsService";
import MailsService from "@/services/MailsService";
import moment from "moment";
import "moment/locale/ru";

export default {
  name: "Profile",
  components: {
    MailsFilter,
    MailsList,
    Pagination,
    AppImage,
  },
  data() {
    return {
      person: {},
      pageSize: 6,
      currentPage: 1,
      mails: [],
      filter: {
        sortBy: "date",
        sortDirection: "desc",
      },
    };
  },
  async created() {
    this.person = await PersonsService.getPerson(this.$route.params.id);

    this.mails = await MailsService.getPersonMails(this.person.id);
  },
  mounted() {
    moment.locale("ru");
  },
  methods: {
    formatDate(date) {
      return moment(date).format("ll");
    },
    changePage(pageNumber) {
      this.currentPage = pageNumber;
      this.$scrollTo("#mails", 0);
    },
  },
  watch: {
    mails() {
      this.currentPage = 1;
    },
  },
  computed: {
    calculatedStretch() {
      const starts = moment(this.person.arrestDate);
      const ends = this.person.releaseDate
        ? moment(this.person.releaseDate)
        : moment();

      const duration = moment.duration(ends.diff(starts));

      let stretch = "";
      if (duration.years() > 0) {
        stretch += `${duration.years()} г. `;
      }
      if (duration.months() > 0) {
        stretch += `${duration.months()} мес. `;
      }
      if (duration.days() > 0) {
        stretch += `${duration.days()} дн.`;
      }

      return stretch;
    },
    sortedMails() {
      let mails = this.mails || [];
      const farInThePast = new Date(null);

      return mails.sort((a, b) => {
        return this.filter.sortDirection === "desc"
          ? moment(b.date || farInThePast).diff(a.date || farInThePast)
          : moment(a.date || farInThePast).diff(b.date || farInThePast);
      });
    },
    totalPages() {
      return Math.ceil(this.mails.length / this.pageSize);
    },
    mailsToShow() {
      return this.sortedMails.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    },
  },
};
</script>

<style scoped lang="scss">
.profile {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 32px;
  padding: 36px 0 50px;

  &__image {
    margin-bottom: 40px;

    @media screen and (max-width: 767px) {
      width: 400px;
      max-width: 100%;
      margin: 0 auto 40px;
    }
  }

  &__history {
    h3 {
      margin-bottom: 18px;
    }
  }

  &__info {
    h2 {
      margin-bottom: 40px;
    }

    .subheading {
      color: #929292;
    }
  }

  &__info-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    h3 {
      margin-bottom: 0;
    }

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;

      h3 {
        width: 100%;
        margin-top: 48px;
        order: 1;
      }

      a {
        order: 0;
      }
    }
  }

  &__info-row {
    display: grid;
    grid-template-columns: 37fr 63fr;
    grid-column-gap: 32px;
    margin-bottom: 58px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
    }
  }

  &__info-column {
    border-top: 2px solid #a4a5a9;

    @media screen and (max-width: 767px) {
      &:last-child {
        border-top: none;
      }
    }
  }

  &__info-block {
    margin-top: 24px;
  }

  &__quote {
    width: 688px;
    max-width: 100%;
    margin: 0 auto 58px;
  }

  &__mails-filter {
    margin-bottom: 32px;
  }

  &__empty-mails-stub {
    border-top: 2px solid #a4a5a9;
    padding: 52px 0 12px;
    margin-bottom: 84px;
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: 100%;
  }
}
</style>
