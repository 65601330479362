<template>
  <li>
    <router-link
      class="person"
      :to="{ name: 'Profile', params: { id: person.id } }"
    >
      <div class="person__image-wrapper">
        <div v-if="person.status === 'in-prison'" class="person__status">
          в заключении
        </div>

        <app-image
          class="person__image"
          :imageUrl="imageUrl"
          altText="Фото политзаключенного"
          proportions="124.348%"
        />
      </div>
      <div class="person__info-wrapper">
        <h3 class="person__name">{{ person.name }}</h3>
        <ul class="person__info">
          <li class="person__stretch">
            <div class="subheading">
              {{ person.status === "free" ? "Был(а) вне воли" : "Вне воли" }}
            </div>
            <div>{{ calculatedStretch }}</div>
          </li>
          <li class="person__mail-count">
            <div class="subheading">Писем</div>
            <div>{{ person.mailCount }}</div>
          </li>
        </ul>
      </div>
    </router-link>
  </li>
</template>

<script>
import AppImage from "@/components/Image.vue";
import imageStub from "@/assets/images/Avatar.png";
import moment from "moment";

export default {
  name: "Tile",
  components: {
    AppImage,
  },
  props: {
    person: Object,
  },
  computed: {
    calculatedStretch() {
      const starts = moment(this.person.arrestDate);
      const ends = this.person.releaseDate
        ? moment(this.person.releaseDate)
        : moment();

      const duration = moment.duration(ends.diff(starts));

      let stretch = "";
      if (duration.years() > 0) {
        stretch += `${duration.years()} г. `;
      }
      if (duration.months() > 0) {
        stretch += `${duration.months()} мес. `;
      }
      if (duration.days() > 0) {
        stretch += `${duration.days()} дн.`;
      }

      return stretch;
    },

    imageUrl() {
      return this.person.avatarUrl || imageStub;
    },
  },
};
</script>

<style scoped lang="scss">
.person {
  text-decoration: none;
  color: inherit;
  display: flex;

  &__image-wrapper {
    width: 208px;
    position: relative;
    padding-right: 27px;
    margin-right: 32px;
    flex-shrink: 0;

    @media screen and (max-width: 1023px) {
      padding-right: 20px;
      margin-right: 12px;
      width: 192px;
    }
  }

  &__image {
    transition: all ease 0.3s;
    filter: grayscale(1);

    @media screen and (max-width: 1023px) {
      filter: none;
    }
  }

  &__status {
    position: absolute;
    font-size: 16px;
    top: 0;
    right: 21px;
    transform-origin: right top;
    transform: rotate(-90deg);

    &--free {
      color: #a5a5a5;
    }
  }

  &__name {
    margin-bottom: 16px;
    transition: all ease 0.3s;
  }

  &__info {
    display: flex;

    @media screen and (max-width: 1023px) {
      flex-wrap: wrap;
    }
  }

  &__stretch,
  &__mail-count {
    width: 50%;

    @media screen and (max-width: 1023px) {
      width: 100%;
    }
  }

  &__stretch {
    @media screen and (max-width: 1023px) {
      margin-bottom: 12px;
    }
  }

  &__mail-count {
    padding-left: 12px;

    @media screen and (max-width: 1023px) {
      padding: 0;
    }
  }

  &:hover,
  &:focus {
    .person__image {
      filter: none;
    }

    .person__name {
      color: #bc2626;
      text-decoration: underline;
    }
  }
}
</style>
