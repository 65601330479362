<template>
  <div class="image">
    <img
      class="image__content"
      :src="imageUrl"
      :alt="altText"
      @load="onImgLoad"
      v-show="isLoaded"
    />
    <div
      v-show="!isLoaded"
      class="image__skeleton"
      :style="{ paddingTop: proportions }"
    >
      <div class="image__skeleton-text">{{ altText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppImage",
  components: {},
  props: {
    imageUrl: [String, Object],
    altText: String,
    proportions: {
      // height / width + %
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  methods: {
    onImgLoad() {
      this.isLoaded = true;
    },
  },
};
</script>

<style lang="scss">
.image {
  &__skeleton {
    width: 100%;
    position: relative;
    padding-top: 140%;
    background-color: #d8d7d7;
    animation-name: blinker;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-duration: 1.5s;
  }

  &__skeleton-text {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__content {
    vertical-align: top;
  }
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
</style>
