<template>
  <ul class="mails-list">
    <mails-list-item
      class="mails-list__item"
      v-for="mail in mails"
      :key="mail.id"
      :mail="mail"
    />
  </ul>
</template>

<script>
import MailsListItem from "./MailsListItem.vue";

export default {
  name: "MailsList",
  components: {
    MailsListItem,
  },
  props: {
    mails: Array,
  },
};
</script>

<style scoped lang="scss">
.mails-list {
  &__item {
    border-top: 2px solid #a4a5a9;
  }
}
</style>
