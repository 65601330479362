import {
  doc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { firestore } from "@/firebase";

const COLLECTION_NAME = "letters";

const lettersCol = collection(firestore, COLLECTION_NAME);

export function toModel(resp) {
  return {
    id: resp.id,
    imagesUrls: resp.pages?.map((p) => p.url),
    date: resp.date?.toDate(),
    text: resp.transcription?.replaceAll("\n", "<br />"),
    quote: resp.quote,
    author: resp.author,
  };
}

async function getPersonMails(personId) {
  const q = query(lettersCol, where("prisoner.id", "==", personId));
  const response = await getDocs(q);

  return response.docs.map((doc) => toModel(doc.data()));
}

async function getMail(id) {
  const docRef = doc(firestore, COLLECTION_NAME, id);
  const response = await getDoc(docRef);

  const person = response.data();
  return person ? toModel(person) : null;
}

async function countMails() {
  const docRef = doc(firestore, "statLetters", "1");
  const response = await getDoc(docRef);

  return response.data()?.countDocs ?? 0;
}

export default {
  getMail,
  getPersonMails,
  countMails,
};
