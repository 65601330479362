<template>
  <div class="pagination">
    <button
      @click="$emit('changePage', currentPage - 1)"
      class="pagination__button"
      :class="{ 'pagination__button--disabled': currentPage === 1 }"
      :disabled="currentPage === 1"
    >
      Пред.
    </button>

    <sliding-pagination
      class="pagination__list"
      :class="{ 'pagination__list--hidden-controls': totalPages > 7 }"
      :current="currentPage"
      :total="totalPages"
      :nonSlidingSize="7"
      @page-change="$emit('changePage', $event)"
    ></sliding-pagination>

    <button
      @click="$emit('changePage', currentPage + 1)"
      class="pagination__button"
      :class="{ 'pagination__button--disabled': currentPage === totalPages }"
      :disabled="currentPage === totalPages"
    >
      След.
    </button>
  </div>
</template>

<script>
import SlidingPagination from "vue-sliding-pagination";

export default {
  name: "Pagination",
  components: {
    SlidingPagination,
  },
  props: {
    totalPages: Number,
    currentPage: Number,
  },
};
</script>

<style lang="scss">
.pagination {
  border-top: 2px solid #a4a5a9;
  padding: 64px 0 50px;
  display: flex;
  justify-content: flex-end;

  &__list {
    margin: 0 24px;

    &--hidden-controls {
      .c-sliding-pagination__list-element {
        &:first-child,
        &:last-child {
          display: none;
        }
      }
    }

    @media screen and (max-width: 767px) {
      margin: 0 10px;
    }
  }

  &__button {
    font-size: 16px;
    color: #444444;
    font-weight: 600;
    padding: 8px;
    transition: all ease 0.2s;

    &:hover:not(.pagination__button--disabled),
    &:focus:not(.pagination__button--disabled) {
      color: #bc2626;
      text-decoration: underline;
    }

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 1023px) {
    justify-content: center;
    padding: 50px 0 36px;
  }
}

.c-sliding-pagination {
  &__list {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__list-element {
    margin: 0 4px;

    &--disabled {
      background: none;
      color: inherit;
      cursor: not-allowed;
    }

    &--active {
      .c-sliding-pagination__page {
        border-bottom: 4px solid #444444;
        pointer-events: none;
      }
    }

    @media screen and (max-width: 767px) {
      margin: 0 2px;
    }
  }

  &__page {
    width: 40px;
    height: 40px;
    padding: 8px;
    border: 1px solid #444444;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    color: #444444;
    display: inline-block;
    text-align: center;
    transition: all ease 0.2s;

    &:hover,
    &:focus {
      background-color: #bc2626;
      color: #fff;
      border-color: #bc2626;
    }

    &[disabled] {
      pointer-events: none;
      cursor: not-allowed;
    }

    @media screen and (max-width: 767px) {
      width: 30px;
      height: 30px;
      font-size: 16px;
      padding: 5px;
    }
  }
}
</style>
