<template>
  <section class="catalogue">
    <h3 class="catalogue__heading">Картотека</h3>
    <cards-filter
      :filter="filter"
      :view="view"
      :searchText="searchText"
      class="catalogue__filter"
      @change-view="view = $event"
      @change-filter="filter = $event"
      @search="searchText = $event"
    />

    <cards-tiles
      class="catalogue__cards"
      v-if="view === 'tiles' && personsToShow.length"
      :persons="personsToShow"
    />
    <cards-list
      class="catalogue__cards"
      v-if="view === 'list' && personsToShow.length"
      :persons="personsToShow"
    />

    <transition name="fade">
      <div
        class="catalogue__empty-stub empty-stub"
        v-show="searchText && !personsToShow.length"
      >
        <div class="empty-stub__text">
          К сожалению по вашему запросу <br />
          ничего не найдено..
        </div>
        <img
          src="@/assets/images/Empty_Stub.svg"
          alt="Иконка отсутствия результатов поиска"
        />
      </div>
    </transition>

    <pagination
      v-if="totalPages > 1"
      :totalPages="totalPages"
      :currentPage="currentPage"
      @changePage="changePage"
    />
  </section>
</template>

<script>
import CardsFilter from "./CardsFilter.vue";
import CardsTiles from "./CardsTiles.vue";
import CardsList from "./CardsList.vue";
import Pagination from "../Pagination.vue";
import PersonsService from "@/services/PersonsService";

export default {
  name: "Catalogue",
  components: {
    CardsFilter,
    CardsTiles,
    CardsList,
    Pagination,
  },
  data() {
    return {
      filter: {
        sortBy: "updatedAt", // updatedAt || count || stretch || status
        sortDirection: "desc", // desc || asc
      },
      searchText: "",
      view: "tiles", // tiles || list
      currentPage: 1,
      persons: [],
    };
  },
  async created() {
    this.persons = await PersonsService.getPersons();
  },
  methods: {
    changePage(pageNumber) {
      this.currentPage = pageNumber;
      this.$scrollTo("#catalogue", 0);
    },
  },
  watch: {
    filteredPersons() {
      this.currentPage = 1;
    },
    view() {
      this.currentPage = 1;
      this.searchText = "";
    },
  },
  computed: {
    pageSize() {
      return this.view === "tiles" ? 12 : 10;
    },
    totalPages() {
      return Math.ceil(this.filteredPersons.length / this.pageSize);
    },
    filteredPersons() {
      let persons = this.persons.filter((person) =>
        person.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
      return persons.sort((a, b) => {
        switch (this.filter.sortBy) {
          case "count":
            return this.filter.sortDirection === "desc"
              ? b.mailCount - a.mailCount
              : a.mailCount - b.mailCount;
          case "stretch":
            return this.filter.sortDirection === "desc"
              ? a.arrestDate - b.arrestDate
              : b.arrestDate - a.arrestDate;
          case "status":
            if (this.filter.sortDirection === "desc") {
              return a.status === "in-prison" ? 1 : -1;
            } else {
              return a.status === "free" ? 1 : -1;
            }
          case "updatedAt":
            return this.filter.sortDirection === "desc"
              ? b.updatedAt - a.updatedAt
              : a.updatedAt - b.updatedAt;
          default:
            break;
        }
      });
    },
    personsToShow() {
      return this.filteredPersons.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    },
  },
};
</script>

<style scoped lang="scss">
.catalogue {
  &__heading {
    border-bottom: 2px solid #a4a5a9;
    padding-bottom: 14px;
    margin-bottom: 32px;
  }

  &__filter {
    margin-bottom: 72px;

    @media screen and (max-width: 1023px) {
      margin-bottom: 48px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 12px;
    }
  }

  &__cards {
    margin-bottom: 64px;

    @media screen and (max-width: 767px) {
      margin-bottom: 56px;
    }
  }

  &__empty-stub {
    margin-bottom: 84px;
  }
}
</style>
