<template>
  <div class="gallery-view">
    <gallery />
  </div>
</template>

<script>
import Gallery from "@/components/gallery/Gallery.vue";

export default {
  name: "GalleryView",
  components: {
    Gallery,
  },
};
</script>

<style scoped lang="scss"></style>
