<template>
  <div class="profile-view">
    <profile />
  </div>
</template>

<script>
import Profile from "@/components/profile/Profile.vue";

export default {
  name: "ProfileView",
  components: {
    Profile,
  },
};
</script>

<style scoped lang="scss"></style>
