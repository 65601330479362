<template>
  <li class="mail">
    <router-link
      class="mail__image-wrapper custom-focus"
      :to="{ name: 'Mail', params: { id: mail.id } }"
    >
      <app-image
        class="mail__image"
        :imageUrl="imageUrl"
        altText="Предпросмотр письма"
        proportions="142.857%"
      />
      <span class="mail__pages-count">{{ mail.imagesUrls.length }} стр.</span>
    </router-link>
    <div class="mail__info">
      <div class="mail__header">
        <div class="mail__date" v-if="mail.date">
          {{ formatDate(mail.date) }}
        </div>
        <div class="mail__mark read-mark" v-if="isRead">
          <img
            class="read-mark__icon"
            src="@/assets/images/Read.svg"
            alt="Отметка прочитанного письма"
          />
          <span>прочитано</span>
        </div>
      </div>
      <blockquote class="mail__quote quote quote--small" v-if="mail.quote">
        <p class="quote__text" v-html="mail.quote"></p>
      </blockquote>

      <router-link
        :to="{ name: 'Mail', params: { id: mail.id } }"
        class="mail__link link"
        ><span class="link__text">Прочитать</span>
        <span class="link__icon">
          <svg
            width="35"
            height="18"
            viewBox="0 0 35 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.7071 3.29285L35.4142 8.99995L29.7071 14.7071L28.2929 13.2928L31.5858 9.99995H0V7.99995H31.5858L28.2929 4.70706L29.7071 3.29285Z"
            /></svg
        ></span>
      </router-link>

      <button v-if="!mail.text" class="mail__button button button--custom">
        Предложить транскрипцию письма
      </button>
    </div>
  </li>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";
import AppImage from "@/components/Image.vue";

export default {
  name: "Mail",
  components: { AppImage },
  data() {
    return {
      imageUrl: "",
    };
  },
  props: {
    mail: Object,
  },
  methods: {
    formatDate(date) {
      return moment(date).format("ll");
    },
  },
  computed: {
    isRead() {
      let readMails = localStorage.getItem("readMails");
      readMails = JSON.parse(readMails) || [];
      return readMails.includes(this.mail.id);
    },
  },
  async created() {
    this.imageUrl = this.mail.imagesUrls[0];
  },
  mounted() {
    moment.locale("ru");
  },
};
</script>

<style scoped lang="scss">
.mail {
  display: flex;
  padding: 24px 0 18px;

  &__image-wrapper {
    width: 208px;
    margin-right: 32px;
    flex-shrink: 0;
    position: relative;
    padding: 5px;
    border: 1px solid #d8d7d7;
    background-color: #eaebed;
    color: inherit;

    @media screen and (max-width: 767px) {
      width: 300px;
      max-width: 100%;
      margin-bottom: 16px;
      margin-right: 0;
    }
  }

  &__image {
    transition: all ease 0.2s;

    &:hover {
      transform: scale(1.03);
    }
  }

  &__pages-count {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 8px;
    background-color: #fff;
    font-size: 14px;
    color: #363636;
  }

  &__info {
    flex: 1;

    @media screen and (max-width: 767px) {
      flex: auto;
      width: 100%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__mark {
    margin-left: 24px;
  }

  &__link {
    margin-top: 32px;
  }

  &__button {
    margin-top: 18px;
  }

  &__quote {
    margin: 18px 0 0;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.read-mark {
  display: inline-flex;
  align-items: center;
  font-size: 9px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 3px 5px;
  border: 1px solid #444444;
  color: #252122;

  &__icon {
    margin-right: 8px;
  }
}
</style>
